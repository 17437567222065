import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const Vendors = (props) => (
    <Layout>
        <Helmet>
            <title>2024 CHCSD Vendors</title>
            <meta name="description" content="2024 CHCSD Vendor Page" />
        </Helmet>

        <div id="main" className="alt">

            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>2024 Vendors</h1>
                        <p><a href="https://www.homeschoolsd.org/conference/" target="_blank">See new website for Vendor Signup Form</a></p>
                    </header>
                    <div className="table-wrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th>Vendor</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><a href="https://www.605tutor.com/" target="_blank">605 Tutor Academy</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://abidingacademy.org/" target="_blank">Abiding Savior Academy</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.actonacademysiouxfalls.org/" target="_blank">Acton Academy Sioux Falls</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://anchoredhrc.com/" target="_blank">Anchored Homeschool Resource Center</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://blc.edu" target="_blank">Bethany Lutheran College</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://biblioplan.net/" target="_blank">BiblioPlan</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.bjupresshomeschool.com" target="_blank">BJU Press Homeschool/Homeworks by Precept</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://YourSeasonedAgent.com" target="_blank">Chad Theisen - Your Seasoned Agent @ Berkshire Hathaway Midwest Realty</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://campnathanael.com/" target="_blank">Camp Nathanael</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://christianlight.org/" target="_blank">Christian Light Education</a></td>
                                </tr>
                                <tr>
                                    <td>Classic Bible Art</td>
                                </tr>
                                <tr>
                                    <td><a href="https://classicalconversations.com/" target="_blank">Classical Conversations</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://demmelearning.com/" target="_blank">Demme Learning (Math-U-See, Spelling You See, Analytical Grammar, WriteShop)</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.dordt.edu/" target="_blank">Dordt University</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.emmaus.edu/" target="_blank">Emmaus Bible College</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://fairsd.org/" target="_blank">FAIRSD (Families for Alternative Instruction Rights in South Dakota)</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://flbc.edu/" target="_blank">Free Lutheran Bible College</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://genesisapologetics.com/" target="_blank">Genesis Apologetics</a></td>
                                </tr>
                                <tr>
                                    <td><a href="" target="_blank">God's Food for Life</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://dsu.edu/admissions/undergraduate/cyber-academy.html" target="_blank">Governors Cyber Academy at Dakota State University</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://healingtheculture.org/" target="_blank">Healing the Culture</a></td>
                                </tr>
                                <tr>
                                    <td><a href="http://www.legacyhomeschool.com/" target="_blank">Heppner's Legacy Homeschool Resources</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://highesthealthchiropractic.com" target="_blank">Highest Health Chiropractic</a></td>
                                </tr>
                                <tr>
                                    <td><a href="http://www.hslda.org/" target="_blank">HSLDA (Home School Legal Defense Association)</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.thehomesteadeducation.com/" target="_blank">Homestead Education</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.thehoodmagazine.com/" target="_blank">'hood Magazine (parent, child, family)</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://homeschoolteenserve.org/" target="_blank">Homeschool Teen Serve</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://hswithconfidence.com/" target="_blank">Homeschooling with Confidence, LLC</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.prnpt.com/locations/331/inreach-physical-therapy-sioux-falls/sioux-falls/location-details" target="_blank">In Reach Physical Therapy</a></td>
                                </tr>
                                <tr>
                                    <td><a href="http://www.inspirationhills.org/" target="_blank">Inspiration Hills Camp & Retreat Center</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.kapeducation.com" target="_blank">KAP Education</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://kidsinministry.org/" target="_blank">Kids in Ministry International</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://lamplighter.net/c/" target="_blank">Lamplighter Ministries</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://readimagineabc.com" target="_blank">Learning To Read America</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.learningrx.com/sioux-falls" target="_blank">LearningRx</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://lifedefensefund.com/" target="_blank">Life Defense Fund</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.sdliteratureandlattes.com/" target="_blank">Literature and Lattes</a> and Local Hybrid School Options</td>
                                </tr>
                                <tr>
                                    <td><a href="https://macksamuels.com/" target="_blank">Mack Samuels</a></td>
                                </tr>
                                <tr>
                                    <td>May Be Lovely</td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.moveher.money/" target="_blank">moveHER Money</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.oakhills.edu/" target="_blank">Oak Hills Christian College</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.wereadtogether.com/" target="_blank">PaperPie</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.patriotacademy.com/" target="_blank">Patriot Academy</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://pennyfordistrict13.com/" target="_blank">Penny BayBridge for Legislature</a></td>
                                </tr>
                                <tr>
                                    <td>Plexus</td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.popplersmusic.com/" target="_blank">Popplers Music, Inc.</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://rightstartmath.com/" target="_blank">RightStart Mathematics by Activities for Learning, Inc.</a></td>
                                </tr>
                                <tr>
                                    <td>Rod and Staff Publishers DBA: Truth and Life Ministry</td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.safesplash.com/locations/sioux-falls-south-sd" target="_blank">SafeSplash Sioux Falls</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.silpada.com/insider/kgenser" target="_blank">Silpada Designs Jewelry</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://nationalguard.com/south-dakota" target="_blank">South Dakota Army National Guard</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://conventionofstates.com/" target="_blank">South Dakota Convention of States</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.sdpb.org/learn/learnersconnection/" target="_blank">South Dakota Public Broadcasting</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.sdrighttolife.org/" target="_blank">South Dakota Right to Life</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.sdstate.edu/" target="_blank">South Dakota State University</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.teachingtextbooks.com/" target="_blank">Teaching Textbooks</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://teenpact.com/" target="_blank">TeenPact Leadership Schools</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.tentm.org/" target="_blank">TENTMAKERS</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://thetpa.info/" target="_blank">Timothy Pastoral Apprenticeship</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.topnotchtreeservice605.com/" target="_blank">Top Notch Tree Service</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://tradesofhope.com/cristasaeger" target="_blank">Trades of Hope</a></td>
                                </tr>
                                <tr>
                                    <td><a href="http://www.ter.org" target="_blank">Trail's End Ranch</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://truenorthhomeschool.academy/" target="_blank">True North Homeschool Academy</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.tpusa.com/startachapter" target="_blank">Turning Point USA</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.sdvisions.com/" target="_blank">Visions Therapy Center</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <center>
                        <p>We're always looking for exciting vendors that can provide value to our conference attendees.</p>
                        <p>If you'd like to be a vendor, a sponsor, have questions about our vendors or have suggestions for vendors you'd like to see at the conference, please contact our <a href="mailto://vendors@homeschoolsd.org">vendor coordinator</a>.</p>
                        <p>The Vendor Hall is now full. New registrations will be placed on a waiting list. Thank you for your support of the conference!</p>
                    </center>
                </div>
            </section>

        </div>

    </Layout>
)

export default Vendors
